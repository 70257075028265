export default [
    {
        path: "/",
        name: "/",
        component: () => import("@/views/HomeView.vue")
    },
    {
        path: "/home",
        name: "Home",
        redirect: "/"
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/account/LoginView.vue")
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("@/views/account/RegisterView.vue")
    },
    {
        path: "/account/details",
        name: "Account Details",
        component: () => import("@/views/accountSettings/AccountDetailsView.vue")
    },
    {
        path: "/account/transactions-history",
        name: "Transactions History",
        component: () => import("@/views/accountSettings/TransactionsHistoryView.vue")
    },
    {
        path: "/account/connected-accounts",
        name: "Connected Accounts",
        component: () => import("@/views/accountSettings/ConnectedAccountsView.vue")
    },
    {
        path: "/itemshop",
        name: "ItemShop",
        component: () => import("@/views/itemshop/ItemShopView.vue")
    },
    {
        path: "/itemshop/buy-archons",
        name: "Buy Archons",
        component: () => import("@/views/itemshop/BuyArchnosView.vue")
    },
    {
        path: "/itemshop/product-details",
        name: "Product Details",
        component: () => import("@/views/itemshop/ProductDetailsView.vue")
    },
    {
        path: "/itemshop/payment",
        name: "Transaction",
        component: () => import("@/views/itemshop/TransactionView.vue")
    },
    {
        path: '/error-404',
        name: 'Error-404',
        component: () => import('@/views/utility/Error404.vue')
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('@/views/utility/Maintenance.vue')
    },
    {
        path: "/:catchAll(.*)",
        name: 'home',
        redirect: '/',
    }
]